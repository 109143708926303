:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 35px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #1b4167;
}
.button:active {
  background-color: #10283e;
}
.section--two .subcontent--1 {
  width: 40%;
}
.section--two .subcontent--2 {
  float: right;
  width: 56%;
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 18px;
    line-height: 1.55555556;
  }
  .moodcontent__suptitle {
    font-size: 16px;
    line-height: 1.25;
  }
  h1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
  h2 {
    font-size: 18px;
    line-height: 1.22222222;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 26px;
    line-height: 1.30769231;
  }
  h4 {
    font-size: 22px;
    line-height: 1.27272727;
  }
  .section--one .area .seam h4 {
    padding-right: 40px;
    background-size: 20px auto;
  }
  h5 {
    font-size: 15px;
    line-height: 1.2;
    padding-bottom: 3px;
  }
  h6 {
    font-size: 26px;
    line-height: 1.30769231;
  }
  .pale {
    font-size: 16px;
    line-height: 1.375;
  }
  .loud {
    font-size: 16px;
    line-height: 1.375;
  }
  .section--one .area .seam {
    font-size: 16px;
    line-height: 1.625;
  }
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 46%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 104%;
  margin-left: -2%;
}
.section--one .area .pure.wide .part.tiny {
  width: 48%;
}
.section--one .area .seam {
  width: 46%;
}
.section--one .area .flat {
  width: 46%;
}
.section--one .area .edge {
  width: 29.33333333%;
}
/*# sourceMappingURL=./screen-medium.css.map */